import React from 'react'
import i18n from 'i18n-js'

import { SPPDailyTable } from '../tables'
import { Slide } from '../layouts'


const SPPMonthlyBudgetedVsActual = props => (
  <Slide title={i18n.t('sppDailyPrAvailabilityTable')} sheet='sheet h'>
    <SPPDailyTable daily_values={props.daily_values} dates={props.dates} />
  </Slide>
)

export default SPPMonthlyBudgetedVsActual
import React from "react"
import styles from './InformationBox.module.css'

const InformationBox = props => (
  <div className={styles.information_box} style={{ marginTop: props.bottom && 'auto' }}>
    <div className={styles.information_box_title}>
      {props.title}
    </div>
    <div className={styles.information_box_content} style={{ minHeight:props.bottom && props.maxHeight, fontSize:props.textFontSize }} dangerouslySetInnerHTML={{__html: props.content}} >
    </div>
  </div>
)
        
InformationBox.defaultProps = {
  maxHeight: 100,
  textFontSize: 12
}

export default InformationBox

import React from 'react'
import i18n from 'i18n-js'

import { Sheet, Content, Header, Footer } from '../components'
import { isEmpty } from '../../../utils'
import styles from './PageIndex.module.css'

const PageIndex = props => (
  <Sheet klassName='sheet cover'>
    <Header title={i18n.t('index')} notHeading />
    <Content>
      <ol className={styles.index_list}>
        <li className={styles.list_item}>{i18n.t('sppMonthlyBudgetedActualProduction')}</li>
        <li className={styles.list_item}>{i18n.t('sppDailyPrAvailability')}</li>
        <li className={styles.list_item}>{i18n.t('sppPerformanceCalculationsRealProduction')}</li>
        <li className={styles.list_item}>{i18n.t('sppPerformanceCalculationsBudgetedProduction')}</li>
        <li className={styles.list_item}>{i18n.t('sppPerformanceCalculationsSystemPerformanceRatio')}</li>
        {!isEmpty(props.data.data.spv_daily_values) && (
          <React.Fragment>
            <li className={styles.list_item}>{i18n.t('spvDailyPr')}</li>
            <li className={styles.list_item}>{i18n.t('spvDailyYield')}</li>
            <li className={styles.list_item}>{i18n.t('spvDailyEnergy')}</li>
          </React.Fragment>
        )}
        <li className={styles.list_item}>{i18n.t('scheduledMonthlyInspectionPlan')}</li>
        <li className={styles.list_item}>{i18n.t('listClosedTickets')}</li>
        <li className={styles.list_item}>{i18n.t('listOpenTickets')}</li>
        <li className={styles.list_item}>{i18n.t('inventory')}</li>
        <li className={styles.list_item}>{i18n.t('commentsAndNotes')}</li>
      </ol>
    </Content>
    <Footer onlyLogo />
  </Sheet>
)

export default PageIndex

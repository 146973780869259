import React from "react";
import styles from './Footer.module.css';

const Footer = props => (
  <div className={styles.footer}>
    <div className={`${styles.footer_content} ${props.dynamicSlide && styles.fixed_footer}`}>
      {!props.onlyLogo && <div style={{ flex: 1, paddingLeft: '5%' }}></div>}
      <div className='solarify_logo'></div>
      {!props.onlyLogo && <div className={styles.page_count}></div>}
    </div>
  </div>
);

export default Footer;

import React from 'react'
import PropTypes from 'prop-types'


const Sheet = props => (
  <div className="sheet" style={{ display:'flex', flexDirection: 'column', justifyContent: 'center' }}>
    {props.children}
  </div>
)

Sheet.propTypes = {
  children: PropTypes.node.isRequired,
  layout: PropTypes.string.isRequired
}

export default Sheet

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from "react-dom/server"
import { Sheet, Footer, Content, Header } from '../components';

const Slide = props => {
  useEffect(() => {
    const dynamicSlide = (removedItems, parentElement) => {
      let footer = document.createElement('div');
      footer.innerHTML = renderToStaticMarkup(<Footer />);

      let a4 = document.createElement('div');
      a4.style.cssText = `
      display: flex;
      flex-direction: column;
      `;
      a4.classList.add("sheet");

      let newConent = document.createElement('div');
      const newID = Math.floor(Math.random() * 100);
      newConent.setAttribute('id', 'subPage' + newID);
      newConent.style.cssText = `
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: white;
    padding: 20px;
    `;
      newConent.append(...removedItems);
      a4.append(newConent, footer);
      insertAfter(a4, parentElement)
      checkOverflow('subPage' + newID);
    }

    const insertAfter = (newNode, existingNode) => {
      existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
    }

    const checkOverflow = (elementId) => {
      const maxHeight = 980;
      let removed = [];
      const pageContent = document.getElementById(elementId ? elementId : props.id);
      const children = Array.from(pageContent.children);
      const contentHeight = pageContent.scrollHeight;
      let long = contentHeight - maxHeight;
      while (long > 0 && children.length > 0) {
        let child = children.pop();
        removed.unshift(child);
        child.remove();
        long = pageContent.scrollHeight - maxHeight;
      }
      if (removed.length > 0) dynamicSlide(removed, pageContent.parentElement);
    }
  }, [])

  return (
    <Sheet klassName={props.sheet || 'sheet'}>
      <Header title={props.title} notHeading={props.notHeading} />
      <Content fixed={props.fixed} id={props.id}>
        {props.children}
      </Content>
      <Footer />
    </Sheet>
  );
};

Slide.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Slide;
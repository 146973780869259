import React from 'react';
import i18n from 'i18n-js';
import styles from './Table.module.css';

export default function TechnicalSpecificationTable(props) {
  const substationList = props.data;
  return (
    <React.Fragment>
      <table width="100%" border="0" className={`${styles.table} ${styles.red_table}`}>
        <thead>
          <tr>
            <td><b>{i18n.t('substation')}</b></td>
            <td><b>{props.itemType === "panels" ? i18n.t('solarPanel') : i18n.t('inverter')}</b></td>
            <td><b>{props.itemType === "panels" ? i18n.t('panelCount') : i18n.t('inverterCount')}</b></td>
          </tr>
        </thead>
        <tbody>
          {substationList.map((sub, idx) => (
            <tr key={idx}>
              <td>{sub.name}</td>
              {sub[props.itemType].length > 0 ? (
                <>
                  <td className={styles.multiRow}>
                    {sub[props.itemType].map((item, idx) => (
                      <div key={idx} className={styles.multiRowItem}>{item.text}</div>
                    ))}
                  </td>
                  <td className={styles.multiRow}>
                    {sub[props.itemType].map((item, idx) => (
                      <div key={idx} className={styles.multiRowItem}>{item.count}</div>
                    ))}
                  </td>
                </>
              ) : (
                <>
                  <td><div>-</div></td>
                  <td><div>-</div></td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  )
};

import React from "react";
import i18n from 'i18n-js';
import { Slide } from "../../layouts";
import { InformationBox, ImageContentViewer } from "../../components";
import styles from './TaskItem.module.css';
import { isSolar } from "../../../../utils";

const TaskItem = props => {
  const { data, index, parentTitle } = props;
  const primaryNum = isSolar(props.stationType) ? '3' : '2';
  const MAX_IMAGE_COUNT = 4;

  const renderTextArea = (props) => (
    <div key={props.idx} className={styles.componentContainer}>
      <InformationBox
        title={props.name}
        content={props.response[0].text}
      />
    </div>
  )

  const renderRadioGroup = (props) => (
    <div key={props.idx} className={styles.componentContainer}>
      <InformationBox
        title={props.name}
        content={props.response[0].text}
      />
    </div>
  )

  const renderImage = (props) => {
    return (
      <div key={props.idx} className={styles.componentContainer}>
        <ImageContentViewer title={props.name} data={props.response} />
      </div>
    )
  }

  const SplitSlideContent = ({ parentIndex, idx, subHeaderNum, compTitle, component }) => {
    return (
      <div key={`${parentIndex}-${idx}`}>
        <Slide title={`${primaryNum}. ${i18n.t('taskList')}`} id={`task-${parentIndex}-${idx}`} notHeading>
          <div className={styles.headerTitle}>{primaryNum}.{index + 1}. {parentTitle}</div>
          <div className={styles.headerSubTitle}>{primaryNum}.{index + 1}.{subHeaderNum + 1}. {compTitle}</div>
          {renderImage({ ...component, response: component.response.slice(0, MAX_IMAGE_COUNT) })}
        </Slide>
        <Slide title={`${primaryNum}. ${i18n.t('taskList')}`} id={`task-${parentIndex}-${idx}`} notHeading>
          <div className={styles.headerTitle}>{primaryNum}.{index + 1}. {parentTitle}</div>
          <div className={styles.headerSubTitle}>{primaryNum}.{index + 1}.{subHeaderNum + 1}. {compTitle}</div>
          {renderImage({ ...component, response: component.response.slice(MAX_IMAGE_COUNT) })}
        </Slide>
      </div>
    )
  }

  const renderSlideContent = (comp, parentIndex, idx, subHeaderNum, compTitle) => {
    if (comp.type === "image" && comp.response.length > MAX_IMAGE_COUNT) {
      return (
        <SplitSlideContent
          parentIndex={parentIndex}
          idx={idx}
          subHeaderNum={subHeaderNum}
          compTitle={compTitle}
          component={comp}
        />
      )
    }

    return (
      <Slide title={`${primaryNum}. ${i18n.t('taskList')}`} key={`task-${parentIndex}-${idx}`} notHeading>
        <div className={styles.headerTitle}>{primaryNum}.{index + 1}. {parentTitle}</div>
        <div className={styles.headerSubTitle}>{primaryNum}.{index + 1}.{subHeaderNum + 1}. {compTitle}</div>
        {comp.type === 'textarea' && renderTextArea(comp)}
        {comp.type === 'image' && renderImage(comp)}
        {comp.type === 'radio-group' && renderRadioGroup(comp)}
      </Slide>
    );
  }

  const renderComponent = (comp, parentIndex, idx, parentTitle, compTitle, subHeaderNum) => {
    if (comp.type === 'subtree') {
      return renderSubTree(comp, parentIndex, idx, parentTitle, compTitle, subHeaderNum);
    } else {
      return renderSlideContent(comp, parentIndex, idx, subHeaderNum, compTitle);
    }
  }

  const renderSubTree = (subItem, parentIndex, idx, parentTitle, compTitle, subHeaderNum) => {
    return subItem.components.map((comp, subIdx) =>
      renderComponent(comp, `${parentIndex}-${idx}`, subIdx, parentTitle, compTitle, subHeaderNum)
    );
  }

  return data.components.map((comp, idx) => {
    return renderComponent(comp, index, idx, parentTitle, comp.name, idx)
  });
};

export default TaskItem;

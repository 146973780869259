import React from 'react'
import moment from 'moment'
import i18n from 'i18n-js'

import {
  Cover,
  PageIndex,
  SPPMonthlyBudgetedVsActual,
  SPPDailyPRandAvailability,
  SPPDailyPRandAvailabilityTable,
  SPVDailyValues,
  OpenTickets,
  ClosedTickets,
  SpareParts,
  SPPPerformanceCalculations,
  EmptyPage,
  InspectionPlan
} from './pages'

import { VendorFooterLogo, VendorHeaderLogo, usePageLayout, setLocale, VendorLogo } from '../../utils'

export default function (props) {
  const { report } = props
  const { station, created_at, meta, data } = report
  const stationImage = station.image ? station.image : VendorLogo[meta.vendor_id]

  usePageLayout("A4", "portrait")
  setLocale(meta.language)

  const getLogo = () => {
    if (station.logo) return station.logo;
    if (station.company.logo) return station.company.logo;
    return VendorHeaderLogo[meta.vendor_id];
  }

  document.title = ''.concat(`${station.name}`, ' - ', i18n.t('monthlySppPerformanceReport'))

  return (
    <React.Fragment>
      <style>{`
        .solarify_logo { flex: 2; text-align: center;}
        .solarify_logo::before {content: url(${VendorFooterLogo[meta.vendor_id]})}
        body{ counter-reset: page_counter headings }
        .report_date:before{ content:'${moment(created_at).format('DD.MM.YYYY')}' }
        @page { size: A4 portrait; }
      `}
      </style>
      {getLogo() &&
      <style>
        {`.mr_company_logo { content: url("${getLogo()}") }`}
      </style>
      }

      <Cover
        image={stationImage}
        reportMonth={report.date_range.lower}
        station={station}
      />
      <PageIndex data={report} />
      <SPPMonthlyBudgetedVsActual dates={data.dates} reports={data.reports} />
      <SPPDailyPRandAvailability dates={data.dates} daily_values={data.daily_values} />
      <SPPDailyPRandAvailabilityTable dates={data.dates} daily_values={data.daily_values} />
      <SPPPerformanceCalculations reports={data.reports} />
      <SPVDailyValues dates={data.dates} daily_values={data.spv_daily_values}/>
      <InspectionPlan inspection={data.monthly_inspection} />
      <ClosedTickets tickets={data.closed_tickets} />
      <OpenTickets tickets={data.open_tickets} />
      <SpareParts tickets={data.spare_parts} />
      <EmptyPage title={i18n.t('commentsAndNotes')} />
    </React.Fragment>
  )
}


import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import i18n from 'i18n-js'

import { InformationBox } from '../components'
import { formatKWH, formatFloat, kWhtoMWH } from '../../../utils'
import styles from './Table.module.css'


export default function BudgetedProductionTable(props) {

  return (
    <React.Fragment>
      <div>
        <InformationBox
          title={i18n.t('methodOfCalculation')}
          content={i18n.t('budgetedProductionMethodofCalculation')}
        />
        <table width="100%" border={0} className={`${styles.small_text} ${styles.red_table} ${styles.table} ${styles.budgeted_table}`}>
          <thead>
            <tr>
              <td rowSpan="2"></td>
              <td colSpan="12">{i18n.t('budgetedProductionValues')}</td>
            </tr>
            <tr>
              <td colSpan="4">{i18n.t('rawData')}</td>
              <td colSpan="2">{i18n.t('actualWeatherData')}</td>
              <td colSpan="6">{i18n.t('calculations')}</td>
            </tr>
            <tr>
              <td rowSpan="2">{i18n.t('months')}</td>
              <td colSpan="4">{i18n.t('simulated')}</td>
              <td colSpan="2">{i18n.t('real')}</td>
              <td rowSpan="2">{i18n.t('calculatedAmbientTemperature')}</td>
              <td rowSpan="2">{i18n.t('additionalDegredation')} (%)</td>
              <td colSpan="4">{i18n.t('adjusted')}</td>
            </tr>
            <tr>
              <td>{i18n.t('production')} (MWh)</td>
              <td>{i18n.t('insolation')} (Wh/m²)</td>
              <td>{i18n.t('ambientTemperature')} (°C)</td>
              <td>{i18n.t('pr')} (%)</td>
              <td>{i18n.t('insolation')} (Wh/m²)</td>
              <td>{i18n.t('moduleTemperature')} (°C)</td>
              <td>{i18n.t('production')} 1 (MWh)</td>
              <td>{i18n.t('production')} 2 (MWh)</td>
              <td>{i18n.t('production')} 3 (MWh)</td>
              <td>{i18n.t('budgetedPR')} (%)</td>
            </tr>
          </thead>
        <tbody>
          {props.reports.map((item, idx) => (
            <tr key={idx}>
              <td>{moment(item.date_range).format('MMMM Y')}</td>
              <td>{item.simulated.production === null ? '-' : kWhtoMWH(item.simulated.production/1000)}</td>
              <td>{item.simulated.irradiance === null ? '-' : formatKWH(item.simulated.irradiance)}</td>
              <td>{item.simulated.temperature === null ? '-' : formatFloat(item.simulated.temperature)}</td>
              <td>{item.simulated.pr === null ? '-' : formatFloat(item.simulated.pr * 100)}</td>
              <td>{item.real.irradiance === null ? '-' : formatKWH(item.real.irradiance)}</td>
              <td>{item.real.t_module === null ? '-' : formatFloat(item.real.t_module)}</td>
              <td>{item.budgeted.ambient_temperature === null ? '-' : formatFloat(item.budgeted.ambient_temperature)}</td>
              <td>{item.budgeted.additional_degredation === null ? '-' : formatFloat(item.budgeted.additional_degredation)}</td>
              <td>{item.budgeted.adjusted_production_with_irr_diff === null ? '-' : kWhtoMWH(item.budgeted.adjusted_production_with_irr_diff)}</td>
              <td>{item.budgeted.adjusted_production_with_irr_and_temp_diff === null ? '-' : kWhtoMWH(item.budgeted.adjusted_production_with_irr_and_temp_diff)}</td>
              <td>{item.budgeted.adjusted_production === null ? '-' : kWhtoMWH(item.budgeted.adjusted_production)}</td>
              <td>{item.budgeted.adjusted_pr === null ? '-' : formatFloat(item.budgeted.adjusted_pr*100)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </React.Fragment>
  )
}

BudgetedProductionTable.propTypes = {
  reports: PropTypes.array.isRequired,
}


import React from 'react'
import i18n from 'i18n-js'
import styles from './Table.module.css'


export default function CumulativeProductionTable(props) {

  return (
    <React.Fragment>
      <table width="100%" border="0" className={`${styles.table} ${styles.red_table}`}>
        <thead>
          <tr>
            <td style={{ width:100 }}></td>
            <td>{i18n.t('cumulativeBudgetedProduction')} (MWh)</td>
            <td>{i18n.t('cumulativeActualProduction')} (MWh)</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{i18n.t('totalProduction')}</td>
            <td>{![null, undefined].includes(props.budgeted) ? props.budgeted.toFixed(2) : '-'}</td>
            <td>{![null, undefined].includes(props.actual) ? props.actual.toFixed(2) : '-'}</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  )
}

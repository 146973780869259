import React from 'react'
import i18n from 'i18n-js'

import { Slide } from '../layouts'
import { SPPDailyChart } from '../charts'
import { InformationBox } from '../components'


const SPPDailyPRandAvailability = props => (
  <Slide title={i18n.t('sppDailyPrAvailability')}>
    <InformationBox
      title={i18n.t('methodOfCalculation')}
      content={i18n.t("sppDailyPrAndAvailabilityMethodofCalculation")}
    />

    <div style={{ marginTop:50 }}>
      <SPPDailyChart
        xSeries={props.dates}
        data={props.daily_values}
      />
    </div>
  </Slide>
)

export default SPPDailyPRandAvailability
import React from "react"


const VerticalFooter = props => (
  <div className="footer">
    <div className="footer-content">
      <div className="page-count" style={{ flex: 1 }}>{props.page}</div>
      <div className="title" style={{ flex: 2 }}>
        <img src={props.logo} style={{ maxHeight: '1cm' }} alt='Company Logo' />
      </div>
      <div style={{ flex: 1, textAlign: "right" }}>{props.right}</div>
    </div>
  </div>
)

export default VerticalFooter

import React from 'react'
import PropTypes from 'prop-types'

import { Sheet, Footer, Content, Header } from '../components'

const Slide = props => {

  return (
    <Sheet klassName={props.sheet || 'sheet'}>
      <Header title={props.title}/>
      <Content>
        {props.children}
      </Content>
      <Footer/>
    </Sheet>
  )
}

Slide.propTypes = {
  title: PropTypes.string.isRequired,
}


export default Slide
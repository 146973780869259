import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'
import moment from 'moment'
import HighchartsReact from 'highcharts-react-official'

import { CumulativeProductionTable } from '../tables'
import { Colors, kWhtoMWH } from '../../../utils'


export default function SPPBudgetedvsActualChart(props) {
    let data = {}
    let i = 0
    let y = 0

    data.x = props.data.map(i => moment(i.date_range).format('MMMM Y'))
    data.budgetedProductions = props.data.map(i => kWhtoMWH(i.budgeted.adjusted_production))
    data.actualProductions = props.data.map(i => kWhtoMWH(i.real.production))

    let cumulativeActual = data.actualProductions.map(d => {
      if(d !== null){
        y +=d
        return y
      }
      return null
    });
    let cumulativeBudgeted = data.budgetedProductions.map(d => {
      if(d!== null){
        i += d
        return i
      }
      return null
    });

    let lastActualCumulative, lastBudgetedCumulative
    if(cumulativeActual)
      lastActualCumulative = cumulativeActual[cumulativeActual.length - 1]
    if(cumulativeBudgeted)
      lastBudgetedCumulative = cumulativeBudgeted[cumulativeBudgeted.length - 1]

    const options = {
      chart: {
        width: 755,
        height: window.outerHeight * 0.45,
      },
      title: {
        text: null
      },
      series: [
        {
          data: data.budgetedProductions,
          type: 'column',
          name: i18n.t('budgetedProduction'),
          color: Colors.lime,
          yAxis: 0,
        },
        {
          data: data.actualProductions,
          color: Colors.dark_green,
          type: 'column',
          name: i18n.t('actualProduction'),
          yAxis: 0
        }
      ],
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            rotation: -90,
            inside: true,
            color: '#fff',
          },
        },
      },
      tooltip: {
        shared: true,
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: data.x,
        gridLineWidth: 1
      },
      yAxis: [
        {
          gridLineWidth: 1,
          title: {
            text: i18n.t('production') + ' (MWh)'
          }
        },
        {
          opposite: true,
          gridLineWidth: 1,
          title: {
            text: i18n.t('cumulativeValues') + ' (MWh)'
          },
        }
      ]
    }

    return (
      <React.Fragment>
        <HighchartsReact options={options} />
        <CumulativeProductionTable
          budgeted={lastBudgetedCumulative}
          actual={lastActualCumulative}
        />
      </React.Fragment>
    )
}

SPPBudgetedvsActualChart.propTypes = {
  data: PropTypes.array.isRequired
}

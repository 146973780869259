import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import HighchartsReact from 'highcharts-react-official'

import { formatPercentage, kWhtoMWH } from '../../../utils'


export default function SPVDailyChart(props) {
  const { data, xSeries, item_key, unit } = props
  const func = props.item_key === 'pr' ? formatPercentage : Number

  const options = {
    chart: {
      width: 755,
      height: window.outerHeight * 0.4
    },
    title:{
      text: null,
    },
    series: Object.entries(data).map(function(key, index) {
      return {
        data: map(key[1][item_key], i => {
          return i === null ? i : parseFloat(func(item_key === 'energy' ? kWhtoMWH(i) : i))
        }),
        name: key[1].name,
        type: 'line',
      }
    }),
    plotOptions: {
      line: {
        marker: {
          enabled: true,
          radius: 2.5
        }
      }
    },
    tooltip: {
      shared: true
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: xSeries,
      gridLineWidth: 1,
    },
    yAxis: {
      gridLineWidth: 1,
      title: {
        text: unit,
      },
      min:0
    }
  }

  if(props.item_key === 'pr'){
    options.yAxis.max = 100
  }

  return (
    <HighchartsReact options={options} />
  )
}

SPVDailyChart.propTypes = {
  data: PropTypes.object.isRequired,
  xSeries: PropTypes.array.isRequired,
  item_key: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired
};

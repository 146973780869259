import React from 'react';
import PropTypes from 'prop-types';


const Sheet = props => (
  <div className="sheet vertical">
    {props.children}
  </div>
);

Sheet.propTypes = {
  children: PropTypes.node.isRequired
};

export default Sheet;

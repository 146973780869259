import React from 'react';
import i18n from 'i18n-js';
import { Slide } from '../layouts';
import { TechnicalSpecificationTable } from '../tables';

const TechnicalSpecification = props => (
  <Slide title={i18n.t('technicalSpecificationsOfStations')} id="technical">
    {props.substationList.length > 0 ? (
      <>
        <div style={{ marginBottom: 50 }}>
          <TechnicalSpecificationTable data={props.substationList} itemType="panels" />
        </div>
        <div>
          <TechnicalSpecificationTable data={props.substationList} itemType="inverters" />
        </div>
      </>
    ) : (
      <p style={{ color: '#e00000' }}>{i18n.t('technicalDetailsCouldNotBeFound')}</p>
    )}
  </Slide>
);

export default TechnicalSpecification;
import React from 'react';
import i18n from 'i18n-js';

import {
  Cover,
  PageIndex,
  ReportDetail,
  TechnicalSpecification,
  TaskList
} from './pages';
import { VendorFooterLogo, VendorHeaderLogo, usePageLayout, setLocale, VendorLogo } from '../../utils';

export default function TaskManagementTemplate(props) {
  const { report } = props;
  const { station, meta, data, date_range } = report;
  const stationImage = station.image ? station.image : VendorLogo[meta.vendor_id];

  usePageLayout("A4", "portrait");
  setLocale(meta.language);

  const getLogo = () => {
    if (station.logo) return station.logo;
    if (station.company.logo) return station.company.logo;
    return VendorHeaderLogo[meta.vendor_id];
  }

  document.title = ''.concat(`${station.name}`, ' - ', i18n.t('taskManagementReport'))

  return (
    <React.Fragment>
      <style>{`
        .solarify_logo { flex: 2; text-align: center;}
        .solarify_logo::before {content: url(${VendorFooterLogo[meta.vendor_id]})}
        body{ counter-reset: page_counter headings }
        @page { size: A4 portrait; }
      `}
      </style>
      {getLogo() &&
        <style>
          {`.mr_company_logo { content: url("${getLogo()}") }`}
        </style>
      }
      <Cover data={data} image={stationImage} station={station} />
      <PageIndex responses={data.responses} stationType={station.type} />
      <ReportDetail data={data} dateRange={date_range} />
      {station.type === 1 && <TechnicalSpecification substationList={station.substations} />}
      <TaskList tasks={data.responses} stationType={station.type} />
    </React.Fragment>
  );
};

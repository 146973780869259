import React from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.css';

const Header = props => (
  <header className={styles.header}>
    <div className={styles.header_content}>
      <div className={props.notHeading ? `${styles.not_heading} ${styles.header_title}` : `${styles.header_title}`}>{props.title}</div>
      <div className={`mr_company_logo ${styles.company_logo}`} />
    </div>
  </header>
);

Header.propTypes = {
  title: PropTypes.string.isRequired
};

export default Header;

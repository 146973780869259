import React from 'react';
import HighchartsReact from 'highcharts-react-official'
import i18n from 'i18n-js'

import { Colors, formatPercentage } from '../../../utils'


export default function SPPDailyChart(props) {
  const { data, xSeries } = props

  const options = {
    chart: {
      width: 755,
      height: window.outerHeight * 0.4
    },
    title: {
      text: null
    },
    series: [
      {
        data: data.pr,
        type: 'line',
        name: 'PR',
        color: Colors.blue,
        yAxis: 0
      },
      {
        data: data.tpr,
        type: 'line',
        name: 'TPR',
        color: Colors.dark_blue,
        yAxis: 0
      },
      {
        data: data.availability,
        type: 'line',
        color: Colors.red,
        name: i18n.t('availability'),
        yAxis: 1
      }
    ],
    plotOptions: {
      line: {
        marker: {
          enabled: true,
          radius: 2.5
        },
        tooltip: {
          pointFormatter: function () {
            return '<span style="font-size: 12px; color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' + formatPercentage(this.y) + '</b><br/>'
          }
        }
      }
    },
    tooltip: {
      shared: true,
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: xSeries,
      gridLineWidth: 1
    },
    yAxis: [{
      gridLineWidth: 1,
      title: {
        text: 'PR-TPR (%)'
      },
      labels: {
        formatter: function () {
          return formatPercentage(this.value);
        }
      },
      max:1,
      min:0
    },
    {
      gridLineWidth: 0,
      opposite: true,
      title: { text: i18n.t('availability') + ' (%)' },
      labels: {
        formatter: function () {
          return formatPercentage(this.value);
        }
      },
      max:1,
      min:0
    }]
  }

  return (
    <React.Fragment>
      <HighchartsReact options={options} />
    </React.Fragment>
  );
}

import React from 'react';
import i18n from 'i18n-js';
import styles from './Table.module.css';

export default function ReportDetailTable(props) {
  const { data, dateRange } = props;
  return (
    <React.Fragment>
      <table width="100%" border="0" className={`${styles.table} ${styles.red_table}`}>
        <thead>
          <tr>
            <td colSpan="2"><b>{i18n.t('taskDetail')}</b></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><b>{i18n.t('reportName')}</b></td>
            <td>{data.name}</td>
          </tr>
          <tr>
            <td><b>{i18n.t('taskDate')}</b></td>
            <td>{i18n.t('startedAt')}: {dateRange.lower} / {i18n.t('finishedAt')}: {dateRange.upper}</td>
          </tr>
          <tr>
            <td><b>{i18n.t('assignee')}</b></td>
            <td>{data.assignee_full_name}</td>
          </tr>
          <tr>
            <td><b>{i18n.t('controller')}</b></td>
            <td>{data.created_by_full_name}</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  )
};

import React from 'react'
import PropTypes from 'prop-types'

import { VerticalSheet, VerticalFooter, VerticalContent, VerticalHeader } from '../components'

import './Slide.css'


const Slide = props => (
  <VerticalSheet>
    <VerticalHeader title={props.title} logo={props.logo} />
    <VerticalContent>
      {props.children}
    </VerticalContent>
    <VerticalFooter page={props.page} logo={props.footerLogo} right={props.footerRight}/>
  </VerticalSheet>
)

Slide.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
}

export default Slide


import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import i18n from 'i18n-js'

import { InformationBox } from '../components'
import { formatFloat, formatKWH, kWhtoMWH } from '../../../utils'
import styles from './Table.module.css'


export default function RealProductionTable(props) {
  return (
    <React.Fragment>
      <div>
      <InformationBox
        title={i18n.t('methodOfCalculation')}
        content={i18n.t('realProductionMethodofCalculation')}
      />
      <table width="100%" border={0} className={`${styles.red_table} ${styles.table}`}>
        <thead>
          <tr>
            <td rowSpan="2"></td>
            <td colSpan="10">{i18n.t('realProductionValues')}</td>
          </tr>
          <tr>
            <td colSpan="4">{i18n.t('rawData')}</td>
            <td colSpan="2">{i18n.t('losses')}</td>
            <td colSpan="2">{i18n.t('modifiedProductionData')}</td>
            <td colSpan="2">{i18n.t('adjustedPr')}</td>
          </tr>
          <tr>
            <td>{i18n.t('months')}</td>
            <td>{i18n.t('production')} (MWh)</td>
            <td>{i18n.t('insolation')} (Wh/m²)</td>
            <td>{i18n.t('moduleTemp')} (°C)</td>
            <td>{i18n.t('pr')} (%)</td>
            <td>{i18n.t('omLoss')} (MWh)</td>
            <td>{i18n.t('nonOmLoss')} (MWh)</td>
            <td>{i18n.t('production')} 1 (MWh)</td>
            <td>{i18n.t('production')} 2 (MWh)</td>
            <td>{i18n.t('pr')} 1 (%)</td>
            <td>{i18n.t('pr')} 2 (%)</td>
          </tr>
        </thead>
        <tbody>
          {props.reports.map((item, idx) => (
            <tr style={{ height:35 }} key={idx}>
              <td>{moment(item.date_range).format('MMMM Y')}</td>
              <td>{item.real.production === null ? '-' : kWhtoMWH(item.real.production)}</td>
              <td>{item.real.irradiance === null ? '-' :formatKWH(item.real.irradiance)} </td>
              <td>{item.real.t_module === null ? '-' :formatFloat(item.real.t_module)}</td>
              <td>{item.real.pr === null ? '-' :formatFloat(item.real.pr * 100)}</td>
              <td>{item.real.om_loss === null ? '-' :kWhtoMWH(item.real.om_loss / 1000)}</td>
              <td>{item.real.not_om_loss === null ? '-' :kWhtoMWH(item.real.not_om_loss / 1000)}</td>
              <td>{item.real.adjusted_production.with_not_om_loss === null ? '-' :kWhtoMWH(item.real.adjusted_production.with_not_om_loss)}</td>
              <td>{item.real.adjusted_production.with_total_loss === null ? '-' :kWhtoMWH(item.real.adjusted_production.with_total_loss)}</td>
              <td>{item.real.adjusted_pr.with_not_om_loss === null ? '-' :formatFloat(item.real.adjusted_pr.with_not_om_loss * 100)}</td>
              <td>{item.real.adjusted_pr.with_total_loss === null ? '-' :formatFloat(item.real.adjusted_pr.with_total_loss * 100)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </React.Fragment>
  )
}

RealProductionTable.propTypes = {
  reports: PropTypes.array.isRequired,
}

import React from 'react'
import i18n from 'i18n-js'

import { Slide } from '../layouts'
import { RealProductionTable, BudgetedProductionTable, SystemPerformanceRatioTable } from '../tables'


const SPPPerformanceCalculations = props => (
  <React.Fragment>
    <Slide title={i18n.t('sppPerformanceCalculationsRealProduction')}>
      <RealProductionTable reports={props.reports} />
    </Slide>

    <Slide title={i18n.t('sppPerformanceCalculationsBudgetedProduction')}>
      <BudgetedProductionTable reports={props.reports} />
    </Slide>

    <Slide title={i18n.t('sppPerformanceCalculationsSystemPerformanceRatio')}>
      <SystemPerformanceRatioTable reports={props.reports} />
    </Slide>
  </React.Fragment>
)

export default SPPPerformanceCalculations
import React from "react"

import styles from './Footer.module.css'

const Footer = props => (
  <div className={styles.footer}>
    <div className={styles.footer_content}>
      {!props.onlyLogo && <div style={{ flex:1, paddingLeft:'5%' }} className="report_date"></div>}
      <div className='solarify_logo'></div>
      {!props.onlyLogo && <div className={styles.page_count}></div> }
    </div>
  </div>
)

export default Footer

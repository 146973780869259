import React from 'react'
import i18n from 'i18n-js'

import { Slide } from './layouts'
import { setLocale, usePageLayout, detectLanguage } from '../../utils'


export default function EmptyTemplate() {

  setLocale(detectLanguage())
  usePageLayout("A4", "landscape")

  return (
    <React.Fragment>
      <style>
        {`@page { size: A4 landscape; }`}
      </style>
      <Slide>
        <h1>{i18n.t('reportNotFound').toUpperCase()}</h1>
      </Slide>
    </React.Fragment>
  )
}


import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'
import styles from './Table.module.css'


export default function SparePartTable(props) {
  return (
    <table width="100%" border={0} className={`${styles.red_table} ${styles.table}`}>
      <thead>
        <tr>
          <td width="450px">{i18n.t('productName')}</td>
          <td width="150px">{i18n.t('totalCount')}</td>
        </tr>
      </thead>
      <tbody>
        {props.spareParts.map((data, idx) => (
          <tr key={idx}>
            <td>{data.name}</td>
            <td>{data.total_quantity}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

SparePartTable.propTypes = {
  spareParts: PropTypes.array.isRequired
}

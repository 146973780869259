import React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import 'moment/locale/tr'

import Highcharts from 'highcharts'
import addMore from "highcharts/highcharts-more";
import i18n from 'i18n-js'
addMore(Highcharts)

Highcharts.setOptions({
  lang: {
    decimalPoint: '.',
    thousandsSep: ','
}
})

moment.locale("tr")

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://solarify.io' : 'http://localhost:8001';

const VendorType = {
  SOLARIFY: 1,
  TESCOMSOLAR: 2,
  COZUM: 3,
  ENERIFY: 4,
  YEOSOLAR: 5,
  MAXIMA: 6,
  WINDIFY: 7,
};

const VendorFooterLogo = {
  [VendorType.SOLARIFY]: '/assets/img/solarify-logo-nav.svg',
  [VendorType.TESCOMSOLAR]: '/assets/img/tescom-logo-nav.png',
  [VendorType.COZUM]: '/assets/img/cozum-logo-nav.png',
  [VendorType.ENERIFY]: '/assets/img/enerify-logo-nav.png',
  [VendorType.YEOSOLAR]: '/assets/img/yeosolar-logo-nav.png',
  [VendorType.MAXIMA]: '/assets/img/maxima-logo-nav.png',
  [VendorType.WINDIFY]: '/assets/img/windify-logo-nav.png',
}

const VendorLogo = {
  [VendorType.SOLARIFY]: '/assets/img/solarify.svg',
  [VendorType.TESCOMSOLAR]: '/assets/img/tescomsolar.svg',
  [VendorType.COZUM]: '/assets/img/cozum.svg',
  [VendorType.ENERIFY]: '/assets/img/enerify.svg',
  [VendorType.YEOSOLAR]: '/assets/img/yeosolar.svg',
  [VendorType.MAXIMA]: '/assets/img/maxima.svg',
  [VendorType.WINDIFY]: '/assets/img/windify.svg',
}

const VendorHeaderLogo = {
  [VendorType.SOLARIFY]: '/assets/img/solarify.svg',
  [VendorType.TESCOMSOLAR]: '/assets/img/tescom-logo-nav.png',
  [VendorType.COZUM]: '/assets/img/cozum.svg',
  [VendorType.ENERIFY]: '/assets/img/enerify.svg',
  [VendorType.YEOSOLAR]: '/assets/img/yeosolar.svg',
  [VendorType.MAXIMA]: '/assets/img/maxima.svg',
  [VendorType.WINDIFY]: '/assets/img/windify.svg',
}

const VendorFooterText = {
  [VendorType.SOLARIFY]: 'solarify.io',
  [VendorType.TESCOMSOLAR]: 'app.tescomsolar.com',
  [VendorType.COZUM]: 'app.cozumsolar.com',
  [VendorType.ENERIFY]: 'enerify.io',
  [VendorType.YEOSOLAR]: 'app.y-eot.com',
  [VendorType.MAXIMA]: 'app.maximaenerji.com.tr',
  [VendorType.WINDIFY]: 'windify.io',
}

const Colors = {
  lime: 'rgb(121, 184, 51)',
  dark_green: 'rgb(0, 100, 40)',
  white: 'rgb(255, 255, 255)',
  black: 'rgb(0, 0, 0)',
  orange: 'rgb(253,174,97)',
  blue: 'rgb(116,173,209)',
  dark_blue: 'rgb(5,43,73)',
  red: 'rgb(255,0,0)'
};

function getUrl(path) {
  return BASE_URL + path;
}

function formatPercentage(val) {
  return numeral(val).format('0.00%');
}

function formatKWH(val) {
  return numeral(val).format('0,0.00');
}

function formatFloat(val) {
  return numeral(val).format('0.00');
}

function formatDateTime(val) {
  return moment(val).format('DD.MM.YYYY HH:mm');
}

function hasIntervention(station) {
  if (station !== undefined && station.non_intervened_loss !== undefined) {
    const energy = station.non_intervened_loss.energy;
    const energyWoInt = station.non_intervened_loss.energy_without_intervention;

    return energy.reduce((a, b) => a + b) - energyWoInt.reduce((a, b) => a + b);
  }
  return false;
}

function paginate(values, page_size, page_number) {
  --page_number;
  return values.slice(page_number * page_size, (page_number + 1) * page_size);
}

function usePageLayout(...tokens) {
  React.useLayoutEffect(() => {
    document.body.classList.add(...tokens)
    return () => {
      document.body.classList.remove(...tokens)
    }
  }, [tokens])
}

function isEmpty(obj) {

  if (obj == null) return true;

  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  if (typeof obj !== "object") return true;

  for (var key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}


function setLocale(language){
  i18n.fallbacks = true
  i18n.locale = language
  i18n.translations[language] = require(`./locale/${language}.json`)
  moment.locale(language)
}

function detectLanguage(){
  const languages = ['tr', 'en', 'de', 'es']
  for(let i in languages){
    if(navigator.language.startsWith(languages[i]))
      return languages[i]
  }
  return 'en'
}

function kWhtoMWH(val){
  return val === null ? val : Number(parseFloat(val/1000).toFixed(2))
}

function isSolar(type) {
  return type === 1;
}

export { kWhtoMWH, VendorLogo, VendorHeaderLogo, VendorFooterLogo, VendorFooterText, getUrl, formatPercentage, formatKWH, formatFloat, formatDateTime, Colors, hasIntervention, paginate, usePageLayout, setLocale, isEmpty, detectLanguage, isSolar };

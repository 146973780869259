import React from "react";
import styles from './ImageContentViewer.module.css';

const ImageContentViewer = props => (
  <>
    <div className={styles.title}>{props.title}</div>
    <div className={styles.gallery}>
      {props.data.map((item) => (
        <figure key={item.id} className={styles.gallery_item}>
          <img src={item.url} className={styles.gallery_img} alt={item.file_name} />
        </figure>
      ))}
    </div>
  </>
);

export default ImageContentViewer;

import React from 'react';
import i18n from 'i18n-js';
import { Sheet, Content, Footer } from '../components';

const Cover = props => {
  const { station, image, data } = props;
  return (
    <Sheet klassName='cover sheet'>
      <Content fixed style={{ padding: 0 }}>
        <img src={image} height="45%" alt="Station Cover" />
        <p style={{ fontWeight: 700, fontSize: 44, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
          {data.name}<br />{i18n.t('taskManagementReport')}
        </p>
        <p style={{ fontWeight: 700, fontSize: 44, marginLeft: 'auto', marginRight: 'auto', marginBottom: 0, marginTop: 50 }}>
          {station.name}
        </p>
        <p style={{ fontWeight: 500, fontSize: 30, marginLeft: 'auto', marginRight: 'auto', marginBottom: 0, marginTop: '1rem' }}>
          {station.nominal_power} {station.nominal_power_unit}
        </p>
        <p style={{ fontWeight: 300, fontSize: 20, marginLeft: 'auto', marginRight: 'auto', marginTop: 'auto' }}>
          {station.address_line}
        </p>
      </Content>
      <Footer onlyLogo />
    </Sheet>
  );
};

export default Cover;

import React from 'react'
import moment from 'moment'
import i18n from 'i18n-js'

import { Sheet, Content, Footer } from '../components'

const Cover = props => {
  const { station, image } = props

  return (
    <Sheet klassName='cover sheet'>
      <Content style={{ padding:0 }}>
        <img src={image} height="45%" alt="Station Cover"/>

        <p style={{ fontWeight: 700, fontSize:44, marginLeft: '5%', marginBottom:0 }}>
          {i18n.t('monthlySppPerformanceReport')} <br/> {station.name}
        </p>

        <p style={{ fontWeight: 700, marginLeft: '5%', fontSize:30 }}>
          {station.company.name} <br/> {moment(props.reportMonth).format('MMMM Y')}
        </p>
      </Content>
      <Footer onlyLogo/>
    </Sheet>
  )
}


export default Cover

import React from 'react'
import PropTypes from 'prop-types'

import styles from './Sheet.module.css'

const Sheet = props => {
  var propsKlassName = props.klassName.split(" ").map(i => `${styles[i]}`).join(" ")
  return (
    <div className={`sheet ${propsKlassName}`}>
      {props.children}
    </div>
  )
}

Sheet.propTypes = {
  children: PropTypes.node.isRequired
}

Sheet.defaultProps = {
  klassName: 'sheet'
}

export default Sheet

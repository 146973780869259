import React from 'react'
import i18n from 'i18n-js'

import { Slide } from '../layouts'
import { SPPBudgetedvsActualChart } from '../charts'
import { InformationBox } from '../components'


const SPPMonthlyBudgetedVsActual = props => (
  <Slide title={i18n.t('sppMonthlyBudgetedActualProduction')}>
    <InformationBox
      title={i18n.t('methodOfCalculation')}
      content={i18n.t("sppMonthlyBudgetedVsActualMethodofCalculation")}
    />

    <div style={{ marginTop:50 }}>
      <SPPBudgetedvsActualChart data={props.reports} />
    </div>
  </Slide>
)

export default SPPMonthlyBudgetedVsActual
import React from 'react'
import i18n from 'i18n-js'

import { Slide } from '../layouts'
import { SparePartTable } from '../tables'


const CHUNK_SIZE_SPARE_PART = 25


const SpareParts = props => {
  let spareParts = props.tickets
  let i, j, components = []

  if(spareParts.length === 0)
    components.push(
      <Slide title={i18n.t('inventory')}>
        {i18n.t('noInventoryRecords')}
      </Slide>
    )
  else
    for (i = 0,j = spareParts.length; i<j; i+=CHUNK_SIZE_SPARE_PART) {
      components.push(
        <Slide title={i18n.t('inventory')} sheet={i !== 0 && 'sheet h'} key={i}>
          <SparePartTable spareParts={spareParts.slice(i, i+CHUNK_SIZE_SPARE_PART)} />
        </Slide>
      )
    }
  return components

}
export default SpareParts
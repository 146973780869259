import React from 'react'
import i18n from 'i18n-js'

import { Slide } from '../layouts'
import { SPVDailyChart } from '../charts'
import { SPVDailyTable } from '../tables'
import { InformationBox } from '../components'
import { isEmpty } from '../../../utils'


const SPVDailyValues = props => {
  const renderSPVDailyTable = (props, item_key, unit, title) => {
    let i, j, components = []
    let daily_values = Object.values(props.daily_values).sort((a, b) => (a.name > b.name) ? 1: -1)
    for (i = 0,j = Object.keys(daily_values).length; i<j; i+=8) {
      components.push(
        <Slide title={i18n.t(title) + ' (' + unit + ')'} sheet='sheet h' key={i}>
          <SPVDailyTable key={i} item_key={item_key} dates={props.dates} daily_values={Object.values(daily_values).slice(i, i+8)} />
        </Slide>
      )
    }
    return components
  }

  return (
  !isEmpty(props.daily_values) && (
    <React.Fragment>
      <SPVDailyPR dates={props.dates} daily_values={props.daily_values} />
      {renderSPVDailyTable(props, 'pr', '%', 'spvDailyPrTable')}
      <SPVDailyYield dates={props.dates} daily_values={props.daily_values} />
      {renderSPVDailyTable(props, 'yield', 'kWh/kW', 'spvDailyYieldTable')}
      <SPVDailyEnergy dates={props.dates} daily_values={props.daily_values} />
      {renderSPVDailyTable(props, 'energy', 'MWh', 'spvDailyEnergyTable')}
    </React.Fragment>
  ))
}

const SPVDailyPR = props => (
  <Slide title={i18n.t('spvDailyPr')}>
    <InformationBox
      title={i18n.t('methodOfCalculation')}
      content={i18n.t('spvDailyPrMethodofCalculation')}
    />
    <div style={{ marginTop:50 }}>
      <SPVDailyChart
        xSeries={props.dates}
        data={props.daily_values}
        item_key='pr'
        unit={i18n.t('pr') + ' (%)'}
      />
    </div>
  </Slide>
)

const SPVDailyYield = props => (
  <Slide title={i18n.t('spvDailyYield')}>
    <InformationBox
      title={i18n.t('methodOfCalculation')}
      content={i18n.t('spvDailyYieldMethodofCalculation')}
    />
    <div style={{ marginTop:50 }}>
      <SPVDailyChart
        xSeries={props.dates}
        data={props.daily_values}
        item_key='yield'
        unit={i18n.t('yield') + ' (kWh/kW)'}
      />
    </div>
  </Slide>
)

const SPVDailyEnergy = props => (
  <Slide title={i18n.t('spvDailyEnergy')}>
    <InformationBox
      title={i18n.t('methodOfCalculation')}
      content={i18n.t('spvDailyEnergyMethodofCalculation')}
    />
    <div style={{ marginTop:50 }}>
      <SPVDailyChart
        xSeries={props.dates}
        data={props.daily_values}
        item_key='energy'
        unit={i18n.t('production') + '(MWh)'}
      />
    </div>
  </Slide>
)


export default SPVDailyValues

import React from 'react'
import i18n from 'i18n-js'
import styles from './Table.module.css'
import numeral from 'numeral'
import moment from 'moment'

import { kWhtoMWH } from '../../../utils'


export default function SPVDailyTable(props) {
  const renderValue = (val) => {
    if(val === null)
      return '-'
    if(props.item_key === 'pr')
      return numeral(100*val).format('0.00')
    if(props.item_key === 'energy')
      return numeral(kWhtoMWH(val)).format('0.00')
    return numeral(val).format('0.00')
  }

  return (
    <table width="100%" border={0} className={`${styles.red_table} ${styles.table}`}>
      <thead>
        <tr>
          <td width="75px">{i18n.t('dates')}</td>
          {Object.values(props.daily_values).map((i, idx) =>
            <td key={idx}>{i.name}</td>
          )}
        </tr>
      </thead>
      <tbody>
        {props.dates.map((y, idx) => (
          <tr key={idx} className={styles.zebra_row}>
            <td><b>{moment(y).format('DD/MM/YYYY')}</b></td>
            {Object.values(props.daily_values).map(i => i[props.item_key][idx]).map((z, _idx) => (
              <td key={_idx}>{renderValue(z)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

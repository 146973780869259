import React from 'react';
import PropTypes from 'prop-types';

const Header = props => (
  <div className="header">
    <div className="header-content">
      <div className="title">{props.title}</div>
      {props.logo && <img className="logo" style={{ marginLeft:15 }} src={props.logo} alt={props.title} />}
    </div>
  </div>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired
};

export default Header;


import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import i18n from 'i18n-js'

import { InformationBox } from '../components'
import { formatFloat, kWhtoMWH } from '../../../utils'
import styles from './Table.module.css'


export default function SystemPerformanceRatioTable(props) {

  return (
    <React.Fragment>
      <div>
      <InformationBox
        title={i18n.t('methodOfCalculation')}
        content={i18n.t('system_PerformanceRatioMethodofCalculation')}
      />
      <table width="100%" border={0} style={{ marginTop:30 }} className={`${styles.red_table} ${styles.table}`}>
        <thead>
          <tr>
            <td rowSpan="2"></td>
            <td colSpan="9">{i18n.t('systemPerformanceMetrics')}</td>
          </tr>
          <tr>
            <td>{i18n.t('budgetedAdjustedProduction')} (MWh)</td>
            <td>{i18n.t('realAdjustedProduction')} (MWh)</td>
            <td>{i18n.t('budgetedAdjustedPr')} (%)</td>
            <td>{i18n.t('realAdjustedPr')} (%)</td>
            <td>{i18n.t('budgetedAdjustedYield')} (kWh/kW)</td>
            <td>{i18n.t('realAdjustedYield')} (kWh/kW)</td>
            <td>{i18n.t('systemPerformanceRatio')} (%)</td>
            <td>{i18n.t('systemAvailabilityRatio')} (%)</td>
            <td>{i18n.t('adjustedAvailabilityRatio')} (%)</td>
          </tr>
        </thead>
        <tbody>
          {props.reports.map((item, idx) =>
            <tr key={idx}>
              <td>{moment(item.date_range).format('MMMM Y')}</td>
              <td>{item.budgeted.adjusted_production === null ? '-' : kWhtoMWH(item.budgeted.adjusted_production)}</td>
              <td>{item.real.adjusted_production.with_not_om_loss === null ? '-' : kWhtoMWH(item.real.adjusted_production.with_not_om_loss)}</td>
              <td>{item.budgeted.adjusted_pr === null ? '-' : formatFloat(item.budgeted.adjusted_pr * 100)}</td>
              <td>{item.real.adjusted_pr.with_not_om_loss === null ? '-' : formatFloat(item.real.adjusted_pr.with_not_om_loss * 100)}</td>
              <td>{item.budgeted.adjusted_yield === null ? '-' : formatFloat(item.budgeted.adjusted_yield)}</td>
              <td>{item.real.adjusted_yield === null ? '-' : formatFloat(item.real.adjusted_yield)}</td>
              <td>{item.real.system_performance_ratio === null ? '-' : formatFloat(item.real.system_performance_ratio * 100)}</td>
              <td>{item.real.system_availability_ratio === null ? '-' : formatFloat(item.real.system_availability_ratio * 100)}</td>
              <td>{item.real.adjusted_availability_ratio === null ? '-' : formatFloat(item.real.adjusted_availability_ratio * 100)}</td>
            </tr>
          )}
        </tbody>
      </table>
      </div>
    </React.Fragment>
  )
}

SystemPerformanceRatioTable.propTypes = {
  reports: PropTypes.array.isRequired,
}

import React from 'react'
import i18n from 'i18n-js'

import { Slide } from '../layouts'


const InspectionPlan = props => (
  <Slide title={i18n.t('scheduledMonthlyInspectionPlan')}>
    {props.inspection ?
      <img width="90%" height="60%" alt='Monthly Inspection' src={props.inspection} style={{ display:'flex', margin:'auto' }} />
      :
      <div>{i18n.t('noScheduledMonthlyInspection')}</div>
    }
  </Slide>
)

export default InspectionPlan

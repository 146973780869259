import React from 'react';
import { TaskItem } from '../components';

const TaskList = props => (
  <React.Fragment>
    {props.tasks.map((task, idx) => (
      <TaskItem
        key={task.idx}
        data={task}
        index={idx}
        parentTitle={task.name}
        stationType={props.stationType}
      />
    ))}
  </React.Fragment>
);

export default TaskList;

import React from 'react'
import i18n from 'i18n-js'

import { Slide } from '../layouts'
import { OpenTicketsTable } from '../tables'


const CHUNK_SIZE_OPEN_TICKETS = 5

const OpenTickets = props => {
  let openTickets = props.tickets
  let i, j, components = []

  if(openTickets.length === 0)
    components.push(
      <Slide title={i18n.t('listOpenTickets')} key={0}>
        {i18n.t('noOpenTickets')}
      </Slide>
    )
  else
    for (i = 0,j = openTickets.length; i<j; i+=CHUNK_SIZE_OPEN_TICKETS) {
      components.push(
        <Slide title={i18n.t('listOpenTickets')} sheet={i !== 0 && 'sheet h'} key={i}>
          <OpenTicketsTable data={openTickets.slice(i, i+CHUNK_SIZE_OPEN_TICKETS)} />
        </Slide>
      )
    }
  return components
}

export default OpenTickets
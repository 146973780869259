
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18n-js'

import { InformationBox } from '../components'
import styles from './Table.module.css'


function renderLongText(longText){
  if(!longText) return '-'
  return longText.length > 200 ? longText.substring(0, 200)+"..." : longText
}


export default function OpenTicketsTable(props) {

  return (
    <React.Fragment>
    <InformationBox
      title={i18n.t('notice')}
      height={20}
      content={i18n.t('detailsOnPortal')}
    />

    {props.data.map((data, idx) => (
      <table width="100%" border={0} className={styles.ticket_report_table} key={idx}>
        <tbody>
          <tr>
            <td colSpan={3} className={styles.ticket_table_key}>{i18n.t('uid')}</td>
            <td colSpan={3} className={styles.ticket_table_value}>{data.uid}</td>
            <td colSpan={3} className={styles.ticket_table_key}>{i18n.t('problemStartedAtShort')}</td>
            <td colSpan={3} className={styles.ticket_table_value}>{data.problem_started_at}</td>
            <td colSpan={3} className={styles.ticket_table_key}>{i18n.t('category')}</td>
            <td colSpan={3} className={styles.ticket_table_value}>{data.category_text}</td>
          </tr>

          <tr style={{ height:10 }}></tr>

          <tr>
            <td colSpan={18} className={styles.ticket_table_key}>{i18n.t('description')}</td>
          </tr>

          <tr style={{ height:40 }}>
            <td colSpan={18} className={`${styles.ticket_table_value} ${styles.ticket_table_long_text}`}>{renderLongText(data.description)}</td>
          </tr>
        </tbody>
      </table>
    ))
    }
    </React.Fragment>
  )
}

OpenTicketsTable.propTypes = {
  data: PropTypes.array.isRequired
}

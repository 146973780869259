import React from 'react'
import i18n from 'i18n-js'

import { TicketDetailTable } from './tables'
import { Slide } from './layouts'
import { usePageLayout, setLocale, VendorHeaderLogo } from '../../utils'


export default function TicketReportTemplate(props) {
  const { data, station, meta } = props.report

  usePageLayout("A4", "portrait")
  setLocale(meta.language)
  document.title = i18n.t("ticketTemplateTitle", {service_number: data.info.uid})

  const getLogo = () => {
    if (station.logo) return station.logo;
    if (station.company.logo) return station.company.logo;
  }

  return (
    <React.Fragment>
      <style>
        {`@page { size: A4 portrait; }`}
      </style>
      <Slide title={i18n.t('serviceReport')} logo={VendorHeaderLogo[meta.vendor_id]} page={1} footerLogo={getLogo()} footerRight={"#" + data.info.uid}>
        <TicketDetailTable ticket={data.info} relatedObjects={data.related_objects} station={station} />
      </Slide>
      {data.attachments.map((attachment,idx) => (
        <Slide key={idx} title={`${i18n.t("attachment")}-${idx+1}`} logo={VendorHeaderLogo[meta.vendor_id]} page={idx+2} footerLogo={getLogo()} footerRight={"#" + data.info.uid}>
          <div style={{ textAlign:'center' }}>
            <img src={attachment.url} alt="" />
            {attachment.description &&
              <div style={{ fontSize: 11, textAlign:'justify', left: 'calc(50% - 300px)', right: 'calc(50% - 300px)', position: 'absolute', marginTop: 20 }}>
                {attachment.description}
              </div>
            }
          </div>
        </Slide>
      ))}
    </React.Fragment>
  )
}

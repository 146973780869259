import React from 'react';
import i18n from 'i18n-js';
import { Slide } from '../layouts';
import { InformationBox } from '../components';
import { ReportDetailTable } from '../tables';

const ReportDetail = props => (
  <Slide fixed title={i18n.t('reportDetail')} id="detail">
    <div style={{ marginBottom: 50 }}>
      <ReportDetailTable data={props.data} dateRange={props.dateRange} />
    </div>
    <InformationBox
      title={i18n.t('description')}
      content={props.data.description}
    />
  </Slide>
);

export default ReportDetail;
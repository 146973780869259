import React, { useEffect } from 'react';
import i18n from 'i18n-js';
import { renderToStaticMarkup } from "react-dom/server"
import { Sheet, Content, Header, Footer } from '../components';
import styles from './PageIndex.module.css';
import { isSolar } from '../../../utils';

const PageIndex = props => {
  const primaryNum = isSolar(props.stationType) ? '3' : '2';

  useEffect(() => {
  const insertAfter = (newNode, existingNode) => {
    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
  }

  const dynamicSlide = (removedItems, parentID) => {
    let footer = document.createElement('div');
    footer.innerHTML = renderToStaticMarkup(<Footer onlyLogo dynamicSlide />);

    let a4 = document.createElement('div');
    a4.style.cssText = `
    display: flex;
    flex-direction: column;
    `;
    a4.classList.add("sheet");
    const newPageID = Math.floor(Math.random() * 100);
    a4.setAttribute('id', 'subPage' + newPageID);

    let newConent = document.createElement('div');
    newConent.style.cssText = `
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: white;
    padding: 20px;
    `;

    let newList = document.createElement('ol');
    const newID = Math.floor(Math.random() * 100);
    newList.setAttribute('id', 'content' + newID);
    newList.style.cssText = `
    list-style-type: none;
    line-height: 40px;
    font-size: 18px;
    font-weight: 600;
    color: #002d53;
    padding-left: 80px;
    `;
    newList.append(...removedItems);
    newConent.append(newList);
    a4.append(newConent, footer);

    const parentElement = document.getElementById(parentID);
    insertAfter(a4, parentElement);
    checkOverflow('content' + newID, 'subPage' + newPageID);
  }

  const checkOverflow = (elementID, parentID) => {
    const maxHeight = 900;
    let removed = [];
    const pageContent = document.getElementById(elementID ? elementID : "taskContainer");
    const children = Array.from(pageContent.children);
    const contentHeight = pageContent.scrollHeight;
    let long = contentHeight - maxHeight;
    while (long > 0 && children.length > 0) {
      let child = children.pop();
      removed.unshift(child);
      child.remove();
      long = pageContent.scrollHeight - maxHeight;
    }
    if (removed.length > 0) dynamicSlide(removed, parentID ? parentID : "indexContainer");
  }

    checkOverflow()
  }, [])

  return (
    <Sheet klassName='sheet cover' id="indexContainer">
      <Header title={i18n.t('index')} notHeading />
      <Content>
        <ol className={styles.index_list}>
          <li className={styles.list_item}>1. {i18n.t('reportDetail')}</li>
          {isSolar(props.stationType) && <li className={styles.list_item}>2. {i18n.t('technicalSpecificationsOfStations')}</li>}
          <li className={styles.list_item}>{primaryNum}. {i18n.t('taskList')}
            <ol className={styles.index_list} id="taskContainer">
              {props.responses.map((item, parentIndex) => (
                <li className={styles.list_item} key={item.idx}>
                  {primaryNum}.{parentIndex + 1}. {item.name}
                  {/* <ol className={styles.index_list}>
                    {item.components.map((subComp, childIndex) =>
                      <li className={styles.list_item} key={subComp.idx}>{primaryNum}.{parentIndex + 1}.{childIndex + 1}. {subComp.name}</li>
                    )}
                  </ol> */}
                </li>
              ))}
            </ol>
          </li>
        </ol>
      </Content>
      <Footer onlyLogo dynamicSlide />
    </Sheet>
  );
};

export default PageIndex;

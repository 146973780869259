import React from 'react';
import { hot } from 'react-hot-loader';
import ReportView from './ReportView'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'normalize.css'
import 'paper-css/paper.min.css'

function App() {
  return (
    <Router>
      <Route exact path="/report/:uid" component={ReportView}/>
    </Router>
  )
}

export default hot(module)(App);

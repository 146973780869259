
import React from 'react'
import i18n from 'i18n-js'
import styles from './Table.module.css'
import numeral from 'numeral'
import moment from 'moment'


export default function SPPDailyTable(props) {
  return (
    <table width="100%" border={0} className={`${styles.red_table} ${styles.table}`}>
      <thead>
        <tr>
          <td width="75px">{i18n.t('dates')}</td>
          <td width="75px">{i18n.t('pr')} (%)</td>
          <td width="75px">{i18n.t('tpr')} (%)</td>
          <td width="75px">{i18n.t('availability')} (%)</td>
        </tr>
      </thead>
      <tbody>
        {props.dates.map((y, idx) => (
          <tr className={styles.zebra_row} key={idx}>
            <td><b>{moment(y).format('DD/MM/YYYY')}</b></td>
            <td>{props.daily_values.pr[idx] === null ? '-' : numeral(props.daily_values.pr[idx] * 100).format('0.00')}</td>
            <td>{props.daily_values.tpr[idx] === null ? '-' : numeral(props.daily_values.tpr[idx] * 100).format('0.0')}</td>
            <td>{props.daily_values.availability[idx] === null ? '-' : numeral(props.daily_values.availability[idx] * 100).format('0.00')}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

import React from "react";
import i18n from 'i18n-js'
import moment from 'moment'

import { formatKWH } from '../../../utils'
import './TicketDetailTable.css'

const units = ["years", "months", "days", "hours", "minutes"]
const dateFormat = "DD.MM.YYYY HH:mm:ss"

export default function TicketDetailTable(props) {
  const { ticket, relatedObjects, station } = props;

  const calculateLossInterval = () => {
    let result = []

    if(!ticket.predicted_loss)
      return result

    const start = moment(ticket.problem_started_at, dateFormat)
    const end = moment(ticket.problem_finished_at, dateFormat)

    units.forEach(unit => {
      let res = end.diff(start, unit)
      start.add(res, unit)

      if(res > 0){
        result.push(res + " " + (res > 1 ? i18n.t(unit) : i18n.t(unit.slice(0, -1))))
      }
    })

    return result
  }

  const lossInterval = calculateLossInterval()

  return (
    <div>
      {ticket.created_automatically &&
        <div style={{ textAlign: "right", fontSize: 9, marginBottom: 4,color: "#ce0814" }}>
          <i>*{i18n.t("autoTicketWarning")}</i>
        </div>
      }
    <table width="100%" border={0} className="ticket-report-table">
      <tbody>
        <tr>
          <td colSpan={3} className="key">{i18n.t("companyName")}</td>
          <td colSpan={3} className="value">{station.company.name}</td>
          <td colSpan={3} className="key">{i18n.t("stationName")}</td>
          <td colSpan={3} className="value">{station.name}</td>
        </tr>
        <tr>
          <td colSpan={3} className="key">{i18n.t("serviceNumber")}</td>
          <td colSpan={3} className="value">{ticket.uid}</td>
          <td colSpan={3} className="key">{i18n.t("problemStartedAt")}</td>
          <td colSpan={3} className="value">{ticket.problem_started_at}</td>
        </tr>
        <tr>
          <td colSpan={3} className="key">{i18n.t("ticketOwner")}</td>
          <td colSpan={3} className="value">{ticket.created_by_text}</td>
          <td colSpan={3} className="key">{i18n.t("problemFinishedAt")}</td>
          <td colSpan={3} className="value">{ticket.problem_finished_at}</td>
        </tr>
        <tr>
          <td colSpan={3} className="key">{i18n.t("assignee")}</td>
          <td colSpan={3} className="value">{ticket.last_assigned_text}</td>
          <td colSpan={3} className="key">{i18n.t("actionStartedAt")}</td>
          <td colSpan={3} className="value">{ticket.action_started_at ? ticket.action_started_at : '—'}</td>
        </tr>
        <tr>
          <td colSpan={3} className="key">{i18n.t("category")}</td>
          <td colSpan={3} className="value">{ticket.category_text}</td>
          <td colSpan={3} className="key">{i18n.t("actionFinishedAt")}</td>
          <td colSpan={3} className="value">{ticket.action_finished_at ? ticket.action_finished_at : '—'}</td>
        </tr>
        <tr>
          <td colSpan={3} className="key">{i18n.t("predictedLoss")}</td>
          <td colSpan={3} className="value">{ticket.predicted_loss > 0 ? (
            <div>
              {formatKWH(ticket.predicted_loss)} Wh<br/>
              ({lossInterval.join(' ')}) <span style={{ color:'red' }}>*</span>
            </div>
          ) : '—'}</td>
          <td colSpan={3} className="key">{i18n.t("responsibleForLoss")}</td>
          <td colSpan={3} className="value">{ticket.responsible_text}</td>
        </tr>
        <tr style={{ height:20, border:'none' }}>
          {lossInterval.length > 0 && (
            <td colSpan={12} style={{ textAlign: 'right', color: 'red', border: 'none', paddingRight: 0 }}>
              *{i18n.t("lossIntervalText")}
            </td>
          )}
        </tr>

        <tr>
          <td colSpan={4} className="key">{i18n.t("description")}</td>
          <td colSpan={4} className="key">{i18n.t("problem")}</td>
          <td colSpan={4} className="key">{i18n.t("solution")}</td>
        </tr>
        <tr style={{ height:110 }}>
          <td colSpan={4} className="value long-text">{ticket.description}</td>
          <td colSpan={4} className="value long-text">{ticket.problem}</td>
          <td colSpan={4} className="value long-text">{ticket.solution}</td>
        </tr>

        {relatedObjects && <tr style={{ height:20 }}></tr>}
        {relatedObjects && <tr><td colSpan={12} className="key">{i18n.t("problemObjects")}</td></tr>}
        {relatedObjects && <tr style={{ height:100 }}><td colSpan={12} className="value long-text">{relatedObjects.join(', ')}</td></tr>}
      </tbody>
    </table>
    </div>
  );
}

import React from 'react'
import i18n from 'i18n-js'

import { Slide } from '../layouts'
import { ClosedTicketsTable } from '../tables'


const CHUNK_SIZE_CLOSED_TICKETS = 5


const ClosedTickets = props => {
  let closedTickets = props.tickets
  let i, j, components = []

  if(closedTickets.length === 0)
    components.push(
      <Slide title={i18n.t('listClosedTickets')} key={0}>
        {i18n.t('noClosedTickets')}
      </Slide>
    )
  else
    for (i = 0,j = closedTickets.length; i<j; i+=CHUNK_SIZE_CLOSED_TICKETS) {
      components.push(
        <Slide title={i18n.t('listClosedTickets')} sheet={i !== 0 && 'sheet h'} key={i}>
          <ClosedTicketsTable data={closedTickets.slice(i, i+CHUNK_SIZE_CLOSED_TICKETS)} />
        </Slide>
      )
    }
  return components
}
export default ClosedTickets
import React from 'react';
import styles from './Content.module.css';

const Content = props => (
  <div className={`contentContainer ${props.fixed ? styles.contentFixed : styles.content}`} style={props.style} id={props.id}>
    {props.children}
  </div>
);

export default Content;

import React from 'react'
import PropTypes from 'prop-types'

import { Sheet } from '../components'


const Slide = props => (
  <Sheet>
    <div style={{ alignSelf:'center' }}>
      {props.children}
    </div>
  </Sheet>
)

Slide.propTypes = {
  children: PropTypes.node.isRequired
}

export default Slide
